h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 2.838125rem;
  margin-bottom: 1.838125rem;
}

body {
  background-color: black;
  color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
  height: 100%;
}

.markdown-content blockquote {
  padding: 20px;
  margin: 20px 0;
  border-left: 5px solid #8a8a8a; /* A solid left border for distinction */
  background-color: black; /* A light background color */
  font-style: italic; /* Italicize text */
  color: white; /* A slightly darker text color for readability */
  font-size: 1.1em; /* Slightly larger font size */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* A subtle shadow for depth */
  line-height: 1.6; /* Improved line spacing for readability */
  position: relative;
}

.markdown-content blockquote:before {
  content: "“";
  font-style: normal;
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 10px;
  vertical-align: -0.4em;
  color: #8a8a8a; /* Color of the opening quote */
  position: relative;
  left: -10px; /* Adjust as necessary for alignment */
}

.markdown-content img {
  max-width: 900px;
  width: 100%;
}

/* Code block container */
pre {
  background-color: #2d2d2d; /* Dark background */
  color: #f8f8f2; /* Light text color */
  font-family: "Courier New", Courier, monospace; /* Monospaced font */
  font-size: 14px;
  line-height: 1.6;
  padding: 16px; /* Padding around the code */
  border-radius: 8px; /* Rounded corners */
  overflow-x: auto; /* Horizontal scroll for long lines */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

/* Inline code styling */
code {
  color: #f92672; /* Pink-red for keywords */
  background-color: transparent;
}

/* Syntax highlighting */
pre code .tag {
  color: #e06c75;
} /* HTML tags */
pre code .attr-name {
  color: #d19a66;
} /* Attribute names */
pre code .attr-value {
  color: #98c379;
} /* Attribute values */
pre code .comment {
  color: #7f848e;
  font-style: italic;
} /* Comments */
pre code .string {
  color: #98c379;
} /* Strings */
pre code .keyword {
  color: #c678dd;
} /* Keywords */
